



















































































































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import metaStore from "@/store/modules/meta";
import pagination from "@/components/UIComponents/Pagination.vue";

@Component({
  components: {
    pagination,
  },
})
export default class RouteListView extends Vue {
  qUsers: any[] = [];

  async created() {
    await metaStore.loadRoutesDD();
  }

  get routeList() {
    return metaStore.routesList;
  }

  get AdminRouter() {
    return AdminRouter;
  }
}
